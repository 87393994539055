import api from "./api";

const route = "/donations";

const CheckoutService = {
  attempt: (amount, payment_method) =>
    api.post(`${route}/attempt`, {
      amount,
      payment_method,
    }),
  postPayPal: (amount) => api.post(`${route}/paypal`, { amount }),
  capturePayPal: (data) => api.post(`${route}/capture`, { ...data }),
};

export default CheckoutService;
