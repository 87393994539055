import React, { useContext, useEffect, useState } from "react";
import { AnalyticsContext } from "../context/AnalyticsContext";
import moment from "moment";

import LocationForm from "../components/global/LocationForm";
import MonthlyStatsChart from "../components/stats/MonthlyStatsChart";
import GlobalStats from "../components/stats/GlobalStats";
import CitiesStatsChart from "../components/stats/CitiesStatsChart";

const Stats = () => {
  const [firstLoad, setFirstLoad] = useState(true);

  const [city, setCity] = useState({});
  const [state, setState] = useState({});
  const [country, setCountry] = useState({});

  const [startDate, setStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const { global, monthly, getGlobalStats, getMonthlyStats } =
    useContext(AnalyticsContext);

  useEffect(() => {
    fetchStats();
  }, []);

  useEffect(() => {
    if (global && global !== null) {
      setFirstLoad(false);
    }
  }, [global]);

  useEffect(() => {
    if (!firstLoad) {
      fetchStats();
    }
  }, [city, country, startDate, endDate]);

  const fetchStats = () => {
    const filters = {
      city: city?.name,
      country: country?.name,
      end_date: endDate,
      start_date: startDate,
    };
    getGlobalStats(filters);
    getMonthlyStats(filters);
  };

  const renderMonthlyStats = () => {
    if (monthly !== null) {
      return <MonthlyStatsChart monthly={monthly} />;
    }
  };

  const renderGlobalStats = () => {
    if (global !== null) {
      return <GlobalStats global={global} />;
    }
  };

  const renderTopCityStats = () => {
    if (global !== null && global?.cities !== null) {
      return <CitiesStatsChart data={global.cities} />;
    }
  };


  return (
    <div className="row justify-content-evenly px-4">
      <>
        <div className="stats-dates-filters col-12 col-md-6 px-0 mb-5">
          <LocationForm
            selectedCountry={country}
            setSelectedCountry={setCountry}
            selectedState={state}
            setSelectedState={setState}
            selectedCity={city}
            setSelectedCity={setCity}
            flex={true}
          />
        </div>
        <div className="col-12 col-md-4 p-0">
          <label>Dates</label>
          <div className="row">
            <div className="col-md-6 col-12 mb-4">
              <input
                type="date"
                value={startDate}
                className="form-control mx-0"
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>

            <div className="col-md-6 col-12 mb-5">
              <input
                type="date"
                value={endDate}
                className="form-control"
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </div>
        </div>
      </>
      <>
        <div className="col-12 col-md-4">
          <div>{renderGlobalStats()}</div>
        </div>
        <div className="col-12 px-0 col-md-6 mt-auto mb-5">
          {renderMonthlyStats()}
        </div>
      </>
      <div className="col-12 px-0 mt-3 col-md-6 mb-3">

        {renderTopCityStats()}
      </div>
      <div className="col-12 px-0 mt-3 col-md-4">
      </div>
    </div>
  );
};

export default Stats;
