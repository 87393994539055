import React, { useContext, useEffect } from "react";
import { DreamsContext } from "../context/DreamsContext";
import { AuthContext } from "../context/AuthContext";
import DreamCard from "../components/dreams/DreamCard";
import DreamFeedPlaceholder from "../components/dreams/DreamFeedPlaceholder";

const DreamList = () => {
  const {
    dreams,
    getDreams,
    saveDreamList,
    clearDreams,
    dream,
  } = useContext(DreamsContext);
  const { user } = useContext(AuthContext);


  const isFavorites = window.location.pathname.includes('/favorites');

  useEffect(() => {
    if (user !== null) {
      if (Array.isArray(dreams)) {
        const current = [...dreams];
        saveDreamList(current, user);
      }

      getDreams({ favorite: isFavorites });

    } else {
      clearDreams();
    }
  }, [user, dream, isFavorites]);

  const renderDreams = (groupIndex) => {
    if (Array.isArray(dreams)) {
      const groupSize = Math.ceil(dreams.length / 4);
      const start = groupSize * groupIndex;
      const end = Math.min(start + groupSize, dreams.length);
      const group = groupIndex >= 0 ? dreams.slice(start, end) : dreams;

      if (start === NaN || end === NaN) return

      return group.map((dream) => (
        <DreamCard key={dream.dream_id} dream={dream} />
      ));
    }
  };

  const renderDreamList = () => {

    if (!Array.isArray(dreams) || dreams.length === 0) {
      return <DreamFeedPlaceholder />;
    }

    return (
      <>
        <div className="row hide-mobile">
          <div className="col-3" style={{ marginTop: "80px", }}>
            {renderDreams(0)}
          </div>
          <div className="col-3" >{renderDreams(1)}</div>
          <div className="col-3" style={{ marginTop: "80px" }}>{renderDreams(2)}</div>
          <div className="col-3" >
            {renderDreams(3)}
          </div>
        </div>
        <div className="show-mobile">
          <div className="d-flex flex-column align-items-center justify-content-center">
            {renderDreams()}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="container row px-0">{renderDreamList()}</div>
  );
};

export default DreamList;
