import {
  GLOBAL_STATS_RECEIVED,
  MONTHLY_STATS_RECEIVED,
} from "../types/analytics";

const AnalyticsReducer = (state, { type, payload }) => {
  switch (type) {
    case GLOBAL_STATS_RECEIVED:
      return { ...state, global: payload };
    case MONTHLY_STATS_RECEIVED:
      return { ...state, monthly: payload };
    default:
      return { ...state };
  }
};
export default AnalyticsReducer;
