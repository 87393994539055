import api from "./api";

const route = "/users";

const userService = {
  getCurrentUser: () => api.get(route),
  getUserLocation: () => api.get(`${route}/location`),
  postSignUp: (data) => api.post(route, { ...data }),
  updateCurrentUser: (data) => api.put(route, { ...data }),
  deleteCurrentUser: () => api.delete(route),
};

export default userService;
