import React, { useContext, useEffect, useState } from "react";
import { InterpretationContext } from "../context/InterpretationContext";
import { ModalContext } from "../context/ModalContext";
import { AuthContext } from "../context/AuthContext";
import { DreamsContext } from "../context/DreamsContext";
import InterpretationsList from "./InterpretationsList";
import Auth from "../components/auth/Auth";
import LocationForm from "../components/global/LocationForm";

const DreamInterpretations = () => {
  const { user, getUserLocation } = useContext(AuthContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { spinner, dream_id, interpretations, postInterpretation, clearInterpretations } = useContext(InterpretationContext);
  const { getDreamImage, dream, spinner: dreamSpinner, saveDream } = useContext(DreamsContext);

  const [city, setCity] = useState(user?.city);
  const [state, setState] = useState(user?.state);
  const [country, setCountry] = useState(user?.country);

  useEffect(() => {
    const setLocationData = (location) => {
      setCity({ name: location.city });
      setState({ name: location.state });
      setCountry({ name: location.country });
    };

    if (dream?.location) {
      setLocationData(dream.location);
    } else if (!dream?.location && user?.location) {
      setLocationData(user.location);
    }
  }, [dream?.location]);

  useEffect(() => {
    if (country?.name === user?.location?.country || dream?.location?.country) {
      return;
    }
    setCity({ name: "" });
    setState({ name: "" });
  }, [country]);

  useEffect(() => {
    getUserLocation();
    return handleClear;
  }, []);

  const handleSaveDream = () => {
    saveDream({
      ...dream,
      location: {
        city: city.name,
        state: state.name,
        country: country.name,
        latitude: city.latitude,
        longitude: city.longitude,
        countryCode: city.countryCode,
      },
    });
    handleClear();
  };

  const handleClear = () => {
    clearModal();
    clearInterpretations();
  };

  const handleAuth = () => {
    clearModal();
    setTimeout(() => {
      modalComponent("Login to Save Your Dreams", <Auth />);
    }, 500);
  };

  const renderImageContainer = () => {
    return (
      <div className="dream-interpretation-image-container">
        <div
          className="dream-interpretation-image"
          style={{
            backgroundImage: `url("${dream.thumbnail}")`,
          }}
        >
          {dreamSpinner ? (
            <div className="spinner-border pb-4"></div>
          ) : (
            <button
              className="btn btn-primary centered-button"
              disabled={dreamSpinner}
              onClick={() => getDreamImage(dream)}
            >
              <i className="fa fa-sync"></i>
            </button>
          )}
        </div>
      </div>
    );
  };

  const renderButtons = () => {
    if (Array.isArray(interpretations) && interpretations.length === 0 && !spinner) {
      return (
        <button className="btn btn-primary w-100" onClick={() => postInterpretation(dream_id)}>
          <i className="fa fa-brain" /> Analyze this dream.
        </button>
      );
    }

    return (
      <button disabled={spinner} onClick={user === null ? handleAuth : handleSaveDream} className="btn btn-primary w-100">
        <i className="fas fa-save me-2"></i> {user === null ? "Save Dream and Analysis" : "Save Dream"}
      </button>
    );
  };

  const renderInterpretation = () => {
    if (!spinner) return <InterpretationsList dream={dream} />;
  };

  return (
    <div className="row">
      <div className="col-12 col-md-7 d-flex flex-column justify-content-between order-md-1 order-2 px-3 mt-1">
        {renderInterpretation()}
        <div className="col-12" >
          <LocationForm
            selectedCountry={country}
            setSelectedCountry={setCountry}
            selectedState={state}
            setSelectedState={setState}
            selectedCity={city}
            setSelectedCity={setCity}
            flex={true}
            style={{ padding: 'none', fontSize: 'small' }}
          />
        </div>
        <div className="col-12 mt-3">
          {renderButtons()}
        </div>
      </div>
      <div className="col-12 col-md-5 d-flex justify-content-center order-md-2 order-1 mt-1 mb-3 px-1">{renderImageContainer()}</div>
    </div>
  );
};

export default DreamInterpretations;
