import { React, } from "react";
import { ReactComponent as MoonSVG } from "../../assets/icons/evolbs_icon17.svg";
import '../../index.css'

const LoadingScreen = () => {
    return (
        <div className="loading-screen-overlay h-100">
            <div className="loading-screen-content d-flex flex-column align-items-center">
                <div className="mb-5">
                    <img
                        style={{ minHeight: "150px", minWidth: "150px" }}
                        src="/images/logo-iceberg.png"
                        className="logo-navbar"
                        alt="Dreams AI"
                    />
                </div>
                <div>
                    <p>Analyzing this dream...</p>
                    <p className="small">It may take up to 5 minutes.</p>
                </div>
                <MoonSVG />
            </div>
        </div>
    )
};

export default LoadingScreen;
