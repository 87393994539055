import React, { useContext, useState } from "react";
import Auth from "../auth/Auth";
import { Link, navigate } from "@reach/router";
import { ModalContext } from "../../context/ModalContext";
import { AuthContext } from "../../context/AuthContext";
import { ReactComponent as ProfilePicIcon } from "../../assets/icons/evolbs_icon12.svg";
import { ReactComponent as AboutIcon } from "../../assets/icons/evolbs_icon8.svg";
import { ReactComponent as HeartIcon } from "../../assets/icons/evolbs_icon7.svg";
import { ReactComponent as DonateIcon } from "../../assets/icons/evolbs_icon6.svg";
import { ReactComponent as StatsIcon } from "../../assets/icons/evolbs_icon5.svg";
import { ReactComponent as PencilIcon } from "../../assets/icons/evolbs_icon9.svg";

const Navbar = () => {
  const { user, signOut } = useContext(AuthContext);
  const { modalComponent } = useContext(ModalContext);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleAuth = () => {
    <Link to="/login"></Link>;
    // modalComponent("Login to Save Your Dreams", <Auth />);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <nav className="navbar navbar-expand-sm navbar-dark mw-100 mb-5">
      <div className="container navbar-container">
        <Link className="navbar-brand p-2" to="/">
          <div>
            <img
              style={{ maxHeight: "40px", marginRight: "10px" }}
              src="/images/logo-iceberg.png"
              className="logo-navbar"
              alt="Dreams AI"
            />
            Evolbs
          </div>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse mw-100" id="navbarNav">
          <div className="container-fluid">
            <ul className="navbar-nav nav justify-content-end">
              <Link className="nav-link purple-text" to="/donate">
                <li className="me-4 nav-item d-flex align-items-center">
                  <DonateIcon />
                  <p className="ms-2 mb-0 p-0">Donate</p>
                </li>
              </Link>

              <Link className="nav-link purple-text" to="/stats">
                <li className="me-4 nav-item d-flex align-items-center">
                  <StatsIcon />
                  <p className="ms-2 mb-0 p-0">Stats</p>
                </li>
              </Link>

              <Link className="nav-link purple-text" to="/favorites">
                <li className="me-4 nav-item d-flex align-items-center">
                  <HeartIcon />
                  <p className="ms-2 mb-0 p-0">Favorites</p>
                </li>
              </Link>

              <Link className="nav-link purple-text" to="/about">
                <li className="me-4 nav-item d-flex align-items-center">
                  <AboutIcon />
                  <p className="ms-2 mb-0 p-0">About</p>
                </li>
              </Link>

              <li className="nav-item dropdown">
                {user === null ? (
                  <Link to="/login">
                    <button className="btn btn-primary">
                      <i className="fa fa-user me-1"></i> Login
                    </button>
                  </Link>
                ) : (
                  <div>
                    <div className="dropdown">
                      <button
                        className="btn p-0 dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ width: "35px" }}
                      >
                        <ProfilePicIcon />
                      </button>
                      <ul className="p-3 dropdown-menu dropdown-menu dropdown-menu-end dropdown-menu-lg-start">
                        <li className="mb-3">
                          <div className="d-flex align-items-center">
                            <Link to="/profile">
                              <div className="me-2" style={{ width: "40px" }}>
                                <ProfilePicIcon />
                              </div>
                            </Link>
                            <div>
                              <p className="small mb-0 ">
                                {user.email}
                              </p>
                            </div>
                            <button
                              className="ms-4 bg-primary"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              onClick={() => navigate("/user/edit")}
                              style={{
                                borderRadius: "50%",
                                height: "30px",
                                width: "30px",
                                border: "none",
                              }}
                            >
                              <PencilIcon />
                            </button>
                          </div>
                        </li>
                        <li className="mb-2 small">
                          <a className="dropdown-item">Change password</a>
                        </li>
                        <li className="mb-2 small">
                          <a className="dropdown-item" onClick={() => navigate("/about")}>Terms & Conditions</a>
                        </li>
                        <li>
                          <a
                            onClick={signOut}
                            className="dropdown-item text-primary d-flex"
                          >
                            <i className="fa fa-sign-out-alt me-1"></i>
                            <p className="m-0 p-0 small">Log Out</p>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
