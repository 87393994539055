import React, { useReducer, createContext, useContext } from "react";
import InterpretationsReducer from "../reducers/InterpretationReducer";
import InterpretationsService from "../services/IntepretationService";
import {
  INTERPRETATIONS_RECEIVED,
  SET_INTERPRETATION,
} from "../types/interpretation";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import { SET_DREAM } from "../types/dreams";
import { ModalContext } from "./ModalContext";

const initialState = {
  interpretations: null,
  interpretation: null,
  dream_id: null,
  spinner: false,
};

export const InterpretationContext = createContext(initialState);

export const InterpretationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(InterpretationsReducer, initialState);

  const { success } = useContext(ModalContext);

  const getDreamInterpretations = (dream_id) => {
    dispatch({ type: SET_DREAM, payload: dream_id });
    InterpretationsService.getDreamInterpretations(dream_id).then((res) => {
      const { interpretations } = res.data;
      dispatch({ type: INTERPRETATIONS_RECEIVED, payload: interpretations });
    });
  };

  const clearInterpretations = () => {
    dispatch({ type: SET_DREAM, payload: null });
    dispatch({ type: INTERPRETATIONS_RECEIVED, payload: null });
  };

  const postInterpretation = (dream_id) => {
    dispatch({ type: SHOW_SPINNER });
    dispatch({ type: SET_DREAM, payload: dream_id });
    InterpretationsService.postInterpretation(dream_id)
      .then((res) => {
        const { interpretation } = res.data;
        dispatch({ type: HIDE_SPINNER });
        dispatch({ type: SET_INTERPRETATION, payload: interpretation });
      })
      .catch((error) => {
        alert(error);
        dispatch({ type: HIDE_SPINNER });
      });
  };

  const saveInterpretation = (interpretation) => {
    InterpretationsService.putInterpretation(interpretation).then(() => {
      if (interpretation.liked) {
        success("Liked interpretation!");
      }
      getDreamInterpretations(interpretation.dream_id);
    });
  };

  return (
    <InterpretationContext.Provider
      value={{
        ...state,
        saveInterpretation,
        postInterpretation,
        clearInterpretations,
        getDreamInterpretations,
      }}
    >
      {children}
    </InterpretationContext.Provider>
  );
};
