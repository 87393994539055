import React, { createContext, useContext, useReducer } from "react";
import AuthService from "../services/AuthService";
import AdjuntosService from "../services/AdjuntosService";
import UserReducer from "../reducers/UserReducer";
import {
  LOGIN,
  LOGOUT,
  SHOW_SPINNER,
  HIDE_SPINNER,
  EDITAR_USUARIO,
  GUARDAR_USUARIO,
  SET_PROPIEDAD_USER,
} from "../types";
import { ModalContext } from "./ModalContext";
import { navigate } from "@reach/router";
import UserService from "../services/UserService";

const initialState = {
  user: null,
  correo: null,
  password: null,
  telefono: null,
  cuenta: null,
  direccion: null,
  spinner: false,
  legacy: null,
};

export const AuthContext = createContext(initialState);

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(UserReducer, initialState);

  const { success, alert, clearModal } = useContext(ModalContext);

  function signIn(email, password) {
    dispatch({ type: SHOW_SPINNER });
    AuthService.signIn(email, password)
      .then((user) => {
        if (user) {
          UserService.getCurrentUser()
            .then((res) => {
              let { user } = res.data;
              dispatch({
                type: LOGIN,
                payload: user,
              });
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.status !== 400) {
                  AuthService.signOut();
                  return alert(error.toString());
                }
              }
              alert(error);
              AuthService.signOut();
            });
        } else {
          alert(
            "Ya tienes cuenta con nosotros pero la contraseña es incorrecta. Por favor, intenta de nuevo"
          );
          dispatch({ type: HIDE_SPINNER });
          AuthService.signOut();
        }
      })
      .catch((error) => {
        if (error.code === "auth/user-not-found") {
          alert(
            "Lo sentimos. No encontramos una cuenta con ese correo. ¡Regístrate!"
          );
        }
        if (error.code === "auth/wrong-password") {
          alert("La contraseña es incorrecta. Por favor, intenta de nuevo");
        } else {
          alert(error.toString());
        }
        dispatch({ type: HIDE_SPINNER });
      });
  }

  function userLoggedIn() {
    dispatch({ type: SHOW_SPINNER });
    AuthService.userLoggedIn(
      (user) => {
        UserService.getCurrentUser()
          .then((res) => {
            clearModal();
            let { user } = res.data;
            dispatch({
              type: LOGIN,
              payload: user,
            });
            dispatch({ type: HIDE_SPINNER });
          })
          .catch((error) => {
            alert(error);
            AuthService.signOut();
          });
      },
      (error) => {
        if (error) {
          alert(error);
          AuthService.signOut();
          navigate("/");
        }
        dispatch({ type: HIDE_SPINNER });
      }
    );
  }

  function signOut() {
    AuthService.signOut()
      .then(() => {
        dispatch({ type: LOGOUT });
        navigate("/");
      })
      .catch((error) => {
        alert(error);
      });
  }

  function signUp(data) {
    const { email, password } = data;
    dispatch({ type: SHOW_SPINNER });
    AuthService.signUp(email, password)
      .then((user) => {
        data.uid = user.user.uid;
        UserService.postSignUp(data)
          .then(() => {
            signIn(email, password);
          })
          .catch((error) => {
            dispatch({ type: HIDE_SPINNER });
            if (error.response) {
              if (error.response.status === 412) {
                return alert(
                  "Ya tienes una cuenta con nosotros. Inicia sesión."
                );
              }
            }
            alert(error);
          });
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        if (error.code) {
          if (
            error.code === "auth/email-already-exists" ||
            error.code === "auth/email-already-in-use"
          ) {
            return alert("Ya tienes una cuenta con nosotros. Inicia sesión.");
          }
        }
        alert(error);
      });
  }

  function getUsuario() {
    UserService.getCurrentUser().then((res) => {
      const { user } = res.data;
      dispatch({ type: LOGIN, payload: user });
    });
  }

  function editarUsuario() {
    dispatch({ type: EDITAR_USUARIO });
  }

  function cancelEdit() {
    dispatch({ type: GUARDAR_USUARIO });
  }

  function setPropiedadUser(key, value) {
    if (key === "idAdjunto") {
      dispatch({ type: SET_PROPIEDAD_USER, payload: { key: "file", value } });
      if (!value)
        dispatch({ type: SET_PROPIEDAD_USER, payload: { key, value } });
    } else {
      if (key === "telefono") {
        value = String(value).replace(/\D/g, "");
        value = String(value).substring(0, 10);
      }
      dispatch({ type: SET_PROPIEDAD_USER, payload: { key, value } });
    }
  }

  function recoverPassword(email) {
    AuthService.recoverPassword(email)
      .then(() => {
        success("Te hemos enviado un correo para reestablecer tu contraseña.");
      })
      .catch((error) => {
        if (error.code === "auth/user-not-found") {
          UserService.recoverPassword(email).then(() => {
            success(
              "¡Te hemos enviado un correo para reestablecer tu contraseña!"
            );
          });
        } else {
          alert("Hubo un error al enviar el correo. Inténtalo más tarde.");
        }
      });
  }

  function updateUsuario(usuario) {
    const promises = [];
    if (usuario.picture && usuario.picture !== null) {
      if (usuario.picture) {
        const promiseAdjunto = new Promise((resolve, reject) => {
          const formData = new FormData();
          formData.append("file", usuario.picture);
          AdjuntosService.postAdjunto(formData).then((res) => {
            const { file_id } = res.data;
            usuario.file_id = file_id;
            resolve();
          });
        });
        promises.push(promiseAdjunto);
      }
    }
    Promise.all(promises).then(() => {
      const data = { ...usuario };
      delete data.file;
      delete data.uid;
      delete data.activo;
      UserService.updateCurrentUser(data)
        .then((res) => {
          dispatch({ type: GUARDAR_USUARIO });
          success("Perfil actualizado con éxito.");
          getUsuario();
        })
        .catch((error) => {
          alert(error);
        });
    });
  }

  const getUserLocation = () => {
    UserService.getUserLocation().then((res) => {
      const location = res.data;
      dispatch({ type: SET_PROPIEDAD_USER, payload: { key: "location", value: location } });
    });
  }

  const verifyEmail = () => {
    AuthService.verifyEmail().then(() => {
      success("Te enviamos un correo para confirmar tu dirección");
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        signIn,
        signUp,
        signOut,
        getUsuario,
        cancelEdit,
        verifyEmail,
        userLoggedIn,
        updateUsuario,
        editarUsuario,
        recoverPassword,
        setPropiedadUser,
        getUserLocation
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
