import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import { SET_DREAM } from "../types/dreams";
import {
  INTERPRETATIONS_RECEIVED,
  SET_INTERPRETATION,
} from "../types/interpretation";

const InterpretationsReducer = (state, { type, payload }) => {
  switch (type) {
    case INTERPRETATIONS_RECEIVED:
      return { ...state, interpretations: payload };
    case SET_INTERPRETATION:
      return { ...state, interpretation: payload };
    case SHOW_SPINNER:
      return { ...state, spinner: true };
    case HIDE_SPINNER:
      return { ...state, spinner: false };
    case SET_DREAM:
      return { ...state, dream_id: payload };
    default:
      return { ...state };
  }
};
export default InterpretationsReducer;
