import React, { useState } from "react";
import LoginForm from "./LoginForm";
import SignUpForm from "./SignUpForm";

const Auth = () => {
  const [isLogin, setIsLogin] = useState(true);

  const renderComponent = () => {
    if (isLogin) {
      return <LoginForm setSignUp={() => setIsLogin(false)} />;
    }
    return <SignUpForm setLogin={() => setIsLogin(true)} />;
  };

  return <div>{renderComponent()}</div>;
};

export default Auth;
