import React from 'react';
import Chart from "react-apexcharts";

const CitiesStatsChart = ({ data }) => {
    const options = {
        chart: {
            type: 'bar',
            height: 350,
            theme: 'dark'

        },
        plotOptions: {
            bar: {
                borderRadius: 10,
            },
        },
        xaxis: {
            categories: data.map((cityData, index) => `#${index + 1} - ${cityData.city}`),
            color: '#fff',
            labels: {
                style: {
                    colors: 'white',
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: 'white',
                },
            },
        },
        tooltip: {
            theme: 'dark',
        },
    };

    const series = [
        {
            name: 'Total Dreams',
            data: data.map(cityData => cityData.total),
            color: "#5f65be",
        },
    ];

    return (
        <div className="stats-card p-2 w-100" >
            <Chart options={options} series={series} type="bar" height={440} width={'100%'} />
        </div >
    );
};

export default CitiesStatsChart;
