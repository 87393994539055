import { getArgs } from "../utils";
import api from "./api";
const route = "/dreams";

const DreamsService = {
  getDreams: (filters) => api.get(`${route}/?${getArgs(filters)}`),
  postDream: (dream) => api.post(route, { ...dream }),
  putDream: (dream) => api.put(route, { ...dream }),
  deleteDream: (dream_id) => api.delete(`${route}/${dream_id}`),
  getDreamImage: (dream) => api.post(`${route}/dream-image/`, { ...dream }),
};
export default DreamsService;
