import React, { useContext, useEffect } from "react";
import { DreamsContext } from "../context/DreamsContext";
import { ModalContext } from "../context/ModalContext";
import { AuthContext } from "../context/AuthContext";
import { getValue } from "../utils";

const DreamForm = () => {
  const { user } = useContext(AuthContext);
  const { alert, clearModal } = useContext(ModalContext);
  const { spinner, dream, setDream, saveDream, createDream, setPropertyDream } =
    useContext(DreamsContext);

  useEffect(() => {
    if (dream === null) {
      createDream();
    }
  }, [dream]);

  const getIcon = () => (!dream.dream_id ? "brain" : "save");

  const getButtonText = () => {
    return !dream.dream_id ? "Analyze Dream" : "Save Dream";
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    if (dream.content === "") {
      return alert("You must add some content to your dream.");
    }

    if (dream.title === "") {
      return alert("You must add a title to your dream.");
    }


    if (user !== null) {
      dream.user_id = user.user_id;
    }

    saveDream(dream);
    handleCancel();
  };

  const handleCancel = () => {
    setDream({});
    clearModal();
  };

  const renderCancel = () => {
    if (user !== null && dream.dream_id) {
      return (
        <button className="btn text-muted mt-3 w-100" onClick={handleCancel}>
          Cancel
        </button>
      );
    }
  };

  const renderForm = () => {
    if (dream !== null) {
      return (
        <form onSubmit={handleSubmit} >
          <div className="card-body">
            <label>
              {dream !== null && dream.dream_id !== undefined
                ? "Edit"
                : "Write "}
              your dream
            </label>
            <textarea
              rows="6"
              value={getValue(dream, "content")}
              className="form-control mb-4"
              placeholder="You can write in English or Spanish"
              onChange={(e) => setPropertyDream("content", e.target.value)}
            />
            <label>Give It a Title</label>
            <input
              type="text"
              value={getValue(dream, "title")}
              className="form-control mb-5"
              placeholder="This is just for you"
              onChange={(e) => setPropertyDream("title", e.target.value)}
            />
            <button disabled={spinner} className="btn btn-primary w-100">
              <i className={`fa fa-${getIcon()} me-2`}></i>
              {getButtonText()}
            </button>
            {renderCancel()}
          </div>
        </form>
      );
    }
  };

  return (
    <div className="card dream-form w-100">
      {renderForm()}
    </div>
  );
};

export default DreamForm;
