import api from "./api";
import { getArgs } from "../utils";

const route = "/analytics";

const AnalyticsService = {
  getGlobalStats: (filters) => api.get(`${route}?${getArgs(filters)}`),
  getMonthlyStats: (filters) => api.get(`${route}/monthly?${getArgs(filters)}`),
};
export default AnalyticsService;
