import React, { useEffect, useContext } from "react";
import { InterpretationContext } from "./context/InterpretationContext";
import DreamInterpretations from "./views/DreamInterpretations";
import SuccessAlert from "./components/common/SuccessAlert";
import ErrorAlert from "./components/common/ErrorAlert";
import { ModalContext } from "./context/ModalContext";
import { AuthContext } from "./context/AuthContext";
import Navbar from "./components/global/Navbar";
import Modal from "./components/common/Modal";
import DreamView from "./views/DreamView";
import { Router } from "@reach/router";
import Stats from "./views/Stats";
import About from "./views/About";
import Donate from "./views/Donate";
import ThankYou from "./views/ThankYou";
import LoginView from "./views/LoginView";
import LoadingScreen from "./components/global/LoadingScreen";
import EditUser from "./views/EditUser";

const Main = () => {
  const { dream_id, spinner: dreamSpinner } = useContext(InterpretationContext);
  const { modalComponent } = useContext(ModalContext);
  const { user, spinner, userLoggedIn } = useContext(AuthContext);

  useEffect(() => {
    userLoggedIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!dreamSpinner && dream_id !== null) {
      modalComponent("Analysis", <DreamInterpretations />);
    }
  }, [dream_id, dreamSpinner]);

  const renderContent = () => {
    if (spinner) {
      return (
        <div className="container py-5 text-center">
          <img src="/images/freud.png" className="freud d-block m-auto mb-3" />
          <div className="spinner-border"></div>
        </div>
      );
    }
    return (
      <Router>
        <DreamView path="/" />
        <DreamView path="/favorites" />
        <Stats path="/stats" />
        <About path="/about" />
        <Donate path="/donate" />
        <LoginView path="/login" />
        <ThankYou path="/thankyou" />
        <EditUser path="user/edit" />
        {user !== null && user.user_id < 3 && <About path="/about" />}
      </Router>
    );
  };

  return (
    <div className="bg-particles container-fluid px-0">
      <Navbar />
      {dreamSpinner && <LoadingScreen />}
      {renderContent()}
      <Modal />
      <SuccessAlert />
      <ErrorAlert />
    </div>
  );
};

export default Main;
