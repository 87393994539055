import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import { validateEmail } from "../../utils";
import LocationsService from "../../services/LocationsService";

const SignUpForm = ({ setLogin }) => {
  const [city, setCity] = useState("");
  const [otro, setOtro] = useState("");
  const [email, setEmail] = useState("");
  const [reason, setReason] = useState("");
  const [country, setCountry] = useState("");
  const [password, setPassword] = useState("");
  const [position, setPosition] = useState(null);
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const { spinner, signUp } = useContext(AuthContext);

  const { alert } = useContext(ModalContext);

  useEffect(() => {
    handleLocation();
  }, []);

  useEffect(() => {
    if (position !== null && (city === "" || country === "")) {
      const { coords } = position;
      LocationsService.getLocation(coords.latitude, coords.longitude).then(
        (res) => {
          const { features } = res.data;
          if (Array.isArray(features)) {
            let result = features[0];
            if (result && result !== null) {
              result = result.properties;
              if (result && result !== null) {
                setCity(result.county);
                setCountry(result.country);
              }
            }
          }
        }
      );
    }
  }, [position]);

  const handleLocation = () => {
    navigator.geolocation.getCurrentPosition(setPosition, (error) =>
      console.log(error)
    );
  };

  const renderOtro = () => {
    if (reason === "Otro") {
      return (
        <div>
          <label>Tell us more</label>
          <input
            type="text"
            className="form-control mb-3"
            value={otro}
            onChange={(e) => setOtro(e.target.value)}
          />
        </div>
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      return alert("Email is not valid.");
    }
    if (String(password).length < 6) {
      return alert("Password must be at least 6 characters long.");
    }
    if (password !== passwordConfirm) {
      return alert("Passwords do not match");
    }
    if (reason === "Otro" && otro === "") {
      return alert(
        "Please complete the field 'How did you find about Dreams AI?'"
      );
    }
    signUp({
      email,
      password,
      reason: reason !== "Otro" ? reason : otro,
      city,
      country,
    });
  };
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label>Email</label>
        <input
          type="email"
          className="form-control mb-3"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label>Password</label>
        <input
          type="password"
          className="form-control mb-3"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <label>Confirm Password</label>
        <input
          type="password"
          className="form-control mb-3"
          value={passwordConfirm}
          onChange={(e) => setPasswordConfirm(e.target.value)}
        />
        <label>City</label>
        <input
          type="text"
          value={city}
          className="form-control mb-3"
          onChange={(e) => setCity(e.target.value)}
        />
        <label>Country</label>
        <input
          type="text"
          value={country}
          className="form-control mb-3"
          onChange={(e) => setCountry(e.target.value)}
        />
        <label>How did you find out about DreamsAI?</label>
        <select
          value={reason}
          className="form-control"
          onChange={(e) => setReason(e.target.value)}
        >
          <option>Recommendation</option>
          <option>Social Media</option>
          <option>Google</option>
          <option>Other</option>
        </select>
        <div className="mt-3">{renderOtro()}</div>
        <button type="submit" className="btn btn-primary w-100" value="Login">
          {spinner ? <div className="spinner-border"></div> : "Sign Up"}
        </button>
      </form>
      <div className="container-fluid px-0 mt-4">
        Already have an account?{" "}
        <button onClick={setLogin} className="btn text-primary">
          Login
        </button>
      </div>
    </div>
  );
};

export default SignUpForm;
