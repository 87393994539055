import { HIDE_SPINNER, SET_EDIT_MODE, SHOW_SPINNER } from "../types";
import {
  APPEND_DREAM,
  CREATE_DREAM,
  DREAMS_RECEIVED,
  REMOVE_DREAM,
  SET_DREAM,
  SET_DREAM_IMAGE,
  SET_PROPERTY_DREAM,
} from "../types/dreams";

const schema = {
  dream_id: "",
  content: "",
};

const DreamsReducer = (state, { type, payload }) => {
  switch (type) {
    case DREAMS_RECEIVED:
      return { ...state, dreams: payload };
    case SET_DREAM:
      return { ...state, dream: payload };
    case SET_PROPERTY_DREAM:
      const dream = { ...state.dream };
      const { key, value } = payload;
      dream[key] = value;
      return { ...state, dream };
    case CREATE_DREAM:
      return { ...state, dream: schema };
    case APPEND_DREAM: {
      let dreams = state.dreams;
      if (!Array.isArray(dreams)) {
        dreams = [];
      } else {
        dreams = [...dreams];
      }
      return { ...state, dreams: [payload, ...dreams] };
    }
    case REMOVE_DREAM: {
      let dreams = state.dreams;
      if (!Array.isArray(dreams)) {
        dreams = [];
      }
      let index = dreams.findIndex(
        (dream) => parseInt(dream.dream_id) === parseInt(payload)
      );
      if (index !== -1) {
        dreams.splice(index, 1);
      }
      return { ...state, dreams };
    }
    case SET_DREAM_IMAGE:
      return { ...state, current_dream_image: payload };
    case SET_EDIT_MODE:
      return { ...state, editMode: payload };
    case SHOW_SPINNER:
      return { ...state, spinner: true };
    case HIDE_SPINNER:
      return { ...state, spinner: false };
    default:
      return { ...state };
  }
};
export default DreamsReducer;
