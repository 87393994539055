import api from "./api";

const route = "/interpretations";

const InterpretationsService = {
  getDreamInterpretations: (dream_id) => api.get(`${route}/dream/${dream_id}`),
  postInterpretation: (dream_id) => api.post(route, { dream_id }),
  putInterpretation: (interpretation) => api.put(route, { ...interpretation }),
};
export default InterpretationsService;
