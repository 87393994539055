import React from 'react';
import NumberCard from '../global/NumberCard';

const GlobalStats = ({ global }) => {

    return (
        <div className="row h-100 justify-content-between align-content-between">
            <NumberCard
                label="Dreams"
                description="daily average"
                value={parseFloat(global.dreams.dreams).toFixed(2)}
            />
            <NumberCard
                label="Users"
                description="daily average"
                value={parseFloat(global.users.users).toFixed(2)}
            />
            <NumberCard
                label="Liked Analysis"
                description="daily average"
                value={parseFloat(global.interpretations.liked).toFixed(2)}
            />
            <NumberCard
                label="Total Dreams"
                value={parseFloat(global.dreams.total).toFixed(2)}
            />
            <NumberCard
                label="Total Users"
                value={parseFloat(global.users.total).toFixed(2)}
            />
            <NumberCard
                label="Dreams per User"
                value={parseFloat(global.user_dreams.user_dreams).toFixed(2)}
            />
        </div>
    );
};

export default GlobalStats;
