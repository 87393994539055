import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";

const LoginForm = ({ setSignUp, prevEmail }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { spinner, signIn } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    signIn(email, password);
  };

  useEffect(() => {
    if (prevEmail && prevEmail !== null) {
      setEmail(prevEmail);
    }
  }, []);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label>Email</label>
        <input
          type="email"
          className="form-control mb-3"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label>
          Password{" "}
          <button
            type="button"
            className="btn btn-link text-muted text-left text-small py-0 mb-1"
            onClick={(e) => {
              e.preventDefault();
              togglePassword();
            }}
          >
            <span className="text-montserrat text-small text-auto">
              {showPassword ? "Hide" : "Show"}
            </span>
          </button>
        </label>
        <input
          type={showPassword ? "text" : "password"}
          className="form-control mb-3"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <button type="submit" className="btn btn-primary w-100" value="Entrar">
          {spinner ? <div className="spinner-border"></div> : "Entrar"}
        </button>
      </form>
      <div className="container-fluid px-0 mt-4">
        Don't have an account?{" "}
        <button onClick={setSignUp} className="btn text-primary">
          Sign Up Now
        </button>
      </div>
      {/*<div className="container-fluid px-0 mt-4">
        <Link to="/recuperar" className="text-muted">
          Forgot your password?
        </Link>
          </div>*/}
    </div>
  );
};

export default LoginForm;
