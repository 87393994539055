import React, { useState, useContext } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import CheckoutService from "../../services/DonationsService";
import { ModalContext } from "../../context/ModalContext";
import { AuthContext } from "../../context/AuthContext";
import { navigate } from "@reach/router";
import { cardStyle } from "../../utils";

const StripeCreditCard = () => {
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [amount, setAmount] = useState(10);
  const [expirationDate, setExpirationDate] = useState('');
  const [cvc, setCvc] = useState('');
  const elements = useElements();
  const stripe = useStripe();

  const { user } = useContext(AuthContext);

  const { alert } = useContext(ModalContext);

  const handleError = (message) => {
    setProcessing(false);
    alert(`An error occurred: ${message}`);
  };

  const handleSuccess = () => {
    setProcessing(false);
    navigate("/thankyou");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (amount < 5) {
      return alert(
        "We're sorry, our payment processor only allows transactions of at least $5 USD."
      );
    }
    setProcessing(true);
    const card = elements.getElement(CardElement);
    const payment_method = await stripe.createPaymentMethod({
      type: "card",
      card,
      billing_details: {
        email: user.email,
      },
    });
    CheckoutService.attempt(amount, payment_method).then(async (res) => {
      const { clientSecret } = res.data;
      if (clientSecret) {
        const payload = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card,
          },
        });
        if (payload.error) {
          handleError(payload.error.message);
        } else {
          handleSuccess();
        }
      } else {
        handleSuccess();
      }
    });
  };

  const handleChange = async (event) => {
    setDisabled(event.empty);
  };

  return (
    <div className="container-fluid px-0">
      <form onSubmit={handleSubmit}>
        <h5 className="border-bottom pb-2 mb-3">Credit/Debit Card</h5>
        <CardElement
          id="card-element"
          options={cardStyle}
          className="form-control pt-2 my-2"
          onChange={handleChange}
          placeholder='Card Number'
        />
        <div className="d-flex">
          <div className="w-50 me-1">
            <label>Expiration Date</label>
            <input
              type="number"
              className="form-control mb-3"
              placeholder="MM/YY"
              value={expirationDate}
              onChange={(e) => setExpirationDate(e.target.value)}
            />
          </div>
          <div className="w-50 ms-1">
            <label>CVC</label>
            <input
              type="number"
              placeholder="CVC"
              className="form-control mb-3"
              value={cvc}
              onChange={(e) => setCvc(e.target.value)}
            />
          </div>
        </div>
        <label>Amount (USD)</label>
        <input
          type="number"
          value={amount}
          className="form-control mb-3"
          onChange={(e) => setAmount(e.target.value)}
        />
        <button className="btn btn-primary w-100" disabled={processing || disabled}>
          {processing ? <div className="spinner-border"></div> : "Donate"}
        </button>
      </form>
    </div>
  );
};

export default StripeCreditCard;
