import Select from "react-select";

const SearchSelect = ({
    label,
    error,
    options,
    getOptionLabel,
    getOptionValue,
    value,
    onChange,
    style,
}) => {
    const baseStyles = {
        control: (provided) => ({
            ...provided,
            border: "none",
            borderRadius: "15px",
            padding: "10px 0",
            backgroundColor: '#383282',
            ...style,
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'white',
        }),
        option: (provided) => ({
            ...provided,
            color: 'white',
            backgroundColor: '#383282',
        }),
        input: (provided) => ({
            ...provided,
            color: 'white',
        }),
        noOptionsMessage: (provided) => ({
            ...provided,
            backgroundColor: '#383282',
            color: 'white',
        }),
        options: (provided) => ({
            ...provided,
            backgroundColor: '#383282',
            color: 'white',
        }),
        menuList: (provided) => ({
            ...provided,
            backgroundColor: '#383282',
        }),
    };

    const customStyles = {
        ...baseStyles,
        control: (provided) => ({
            ...baseStyles.control(provided),
            boxShadow: error ? "0 0 0 1.5px #dc3545" : "none",
        }),
    };

    return (
        <div className="w-100">
            <label>{label}</label>
            <Select
                styles={customStyles}
                className="text-dark mb-4"
                options={options}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
                value={value}
                onChange={onChange}
            />
        </div>
    );
};

export default SearchSelect;
