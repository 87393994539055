import React, { useContext, useEffect } from "react";
import { InterpretationContext } from "../context/InterpretationContext";
import moment from "moment";

const InterpretationsList = ({ dream }) => {
  const {
    dream_id,
    interpretations,
    saveInterpretation,
    getDreamInterpretations,
  } = useContext(InterpretationContext);


  useEffect(() => {
    getDreamInterpretations(dream_id);

  }, [dream_id]);

  const toggleFavorite = (interpretation) => {
    interpretation.liked = !interpretation.liked;
    saveInterpretation(interpretation);
  };

  const renderInterpretations = () => {
    if (Array.isArray(interpretations)) {
      if (interpretations.length > 0) {

        return interpretations.map((interpretation) => (

          <div key={interpretation.interpretation_id}>

            <div className="mb-2 d-flex justify-content-between">
              <div className="col-10">
                <p>What was my dream?</p>
                <p className="purple-text">{dream.content}</p>
              </div>
              <div className="col-2  d-flex justify-content-end">
                <button
                  className="btn p-0"
                  style={{ height: "30px", width: '30px' }}
                  onClick={() => toggleFavorite(interpretation)}
                >
                  <i
                    className={
                      interpretation.liked
                        ? "fas fa-heart text-primary"
                        : "far fa-heart text-white"
                    }
                  />
                </button>
              </div>

            </div>
            <div className="mb-2">
              <p className="mb-1">My dream Name</p>
              <p className="purple-text">{dream.title}</p>
            </div>

            <div className="mb-2">
              <p className="mb-1">What could this mean?</p>
              <p className="mb-0">{interpretation.content}</p>
            </div>
            <div className="row align-items-center">
              <div className="col-12">
                <p className="small mb-0 text-secondary">
                  According to {interpretation.theory}.
                </p>
              </div>
            </div>
            <div>
              <p className="small purple-text">
                {moment().format("YYYY-MM-DD")}
              </p>
            </div>
          </div>
        ));
      }
      return <p style={{ minWidth: '800px' }}>No analysis saved for this dream.</p>;
    }
    return <div className="spinner-border"></div>;
  };
  return <div>{renderInterpretations()}</div>;
};

export default InterpretationsList;
