import React, { useState, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import { validateEmail } from "../../utils";
import LocationForm from "../global/LocationForm";
import { useEffect } from "react";

const UserForm = () => {
    const { spinner, user, updateUsuario, getUserLocation } = useContext(AuthContext);
    const { alert } = useContext(ModalContext);

    const [email, setEmail] = useState(user?.email);
    const [city, setCity] = useState(user?.city);
    const [state, setState] = useState(user?.state);
    const [country, setCountry] = useState(user?.country);

    useEffect(() => {
        getUserLocation()
    }, [])

    useEffect(() => {
        if (!user?.location) return
        setCity({ name: user.location.city })
        setState({ name: user.location.state })
        setCountry({ name: user.location.country })
    }, [user?.location])

    useEffect(() => {
        if (country?.name == user?.location?.country) { return }
        setCity({ name: "" })
        setState({ name: "" })
    }, [country])

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateEmail(email)) {
            return alert("Email is not valid.");
        }

        updateUsuario({
            email,
            location: {
                city: city.name,
                state: state.name,
                country: country.name,
                latitude: city.latitude,
                longitude: city.longitude,
                countryCode: city.countryCode
            }
        });
    };
    return (
        <div>
            <form onSubmit={handleSubmit}>
                <label>Email</label>
                <input
                    type="email"
                    className="form-control mb-3"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <LocationForm
                    selectedCountry={country}
                    setSelectedCountry={setCountry}
                    selectedState={state}
                    setSelectedState={setState}
                    selectedCity={city}
                    setSelectedCity={setCity}
                    flex={true}
                />
                <button type="submit" className="btn btn-primary w-100 mt-4">
                    {spinner ? <div className="spinner-border"></div> : "Edit User"}
                </button>
            </form>
        </div>
    );
};

export default UserForm;
