import React from "react";

const Terms = () => {
  return (
    <div className="card mb-3">
      <div className="card-body">
        <h2>Terms of Service</h2>
        <p>Last updated: 05-01-2023</p>
        <h3>Introduction</h3>
        <p>
          Welcome to Imago, a website dedicated to providing AI-driven dream
          interpretations using ChatGPT technology. These Terms of Service
          ("Terms") govern your access to and use of our website, products, and
          services ("Services"). By accessing or using our Services, you agree
          to be bound by these Terms. If you do not agree to these Terms, do not
          access or use our Services.
        </p>
        <h3>Eligibility</h3>
        <p>
          To use our Services, you must be at least 13 years old. By accessing
          or using our Services, you represent and warrant that you meet the age
          requirement.
        </p>
        <h3>Dream Interpretations</h3>
        <p>
          Imago provides AI-driven dream interpretations using ChatGPT
          technology. These interpretations are not to be considered real,
          factual, or accurate, and are intended for entertainment purposes
          only. Imago and its creators do not claim to provide any clinical,
          psychological, or therapeutic validation for the interpretations
          provided.
        </p>
        <h3>Research Purpose and Free Access</h3>
        <p>
          The primary objective of Imago is to facilitate research in the field
          of AI-driven dream interpretation using ChatGPT technology. As part of
          our commitment to advancing knowledge in this area, we provide free
          access to our Services. By using our Services, you understand and
          acknowledge that your participation contributes to the ongoing
          research and development of our AI technology. We appreciate your
          support in this endeavor and strive to enhance our understanding of
          AI-driven dream interpretation through your engagement with our
          Services.
        </p>
        <h3>Limitation of Liability</h3>
        <p>
          Imago and its creators are not responsible for any actions taken based
          on the interpretations provided. Users are solely responsible for
          their actions and decisions made as a result of using our Services.
          Imago and its creators expressly disclaim any liability or
          responsibility for any harm, loss, or damage that may arise from your
          use of our Services.
        </p>
        <h3>Data Usage</h3>
        <p>
          By using our Services, you grant Imago and its creators the right to
          collect, store, and use all data generated through your use of our
          Services, including but not limited to dream descriptions,
          interpretations, and any other information provided by you. This data
          may be used for research, marketing, business, and other related
          purposes, at our discretion. We may also share this data with third
          parties, as necessary, to provide, maintain, and improve our Services.
        </p>
        <h3>Intellectual Property Rights</h3>
        <p>
          All content and materials available on our website, including but not
          limited to text, graphics, logos, and images, are the property of
          Imago or its licensors, and are protected by copyright, trademark, and
          other intellectual property laws. You may not use, reproduce, or
          distribute any of our content or materials without our express written
          consent.
        </p>
        <h3>Changes to Terms</h3>
        <p>
          We reserve the right to modify or update these Terms at any time, in
          our sole discretion. Any changes will be effective immediately upon
          posting the updated Terms on our website. Your continued use of our
          Services after such changes constitutes your acceptance of the new
          Terms. If you do not agree to any changes to these Terms, you must
          stop using our Services.
        </p>
        <h3>Termination</h3>
        <p>
          We reserve the right, in our sole discretion, to suspend or terminate
          your access to our Services at any time, for any reason, without prior
          notice or liability. You may terminate your use of our Services at any
          time by discontinuing your access to and use of our website.
        </p>
        <h3>Governing Law</h3>
        <p>
          These Terms shall be governed by and construed in accordance with the
          laws of the jurisdiction in which Imago is based, without regard to
          its conflict of law provisions.
        </p>
        <h3>Privacy</h3>
        <p>
          Your privacy is important to us. Please review our Privacy Policy,
          which is incorporated into these Terms, to understand our practices
          regarding the collection, use, and disclosure of your personal
          information.
        </p>
        <h3>User Conduct</h3>
        <p>
          By using our Services, you agree to comply with all applicable laws,
          rules, and regulations. You also agree not to:
        </p>
        <ol>
          <li>
            Use our Services for any unlawful, fraudulent, or malicious purposes
          </li>
          <li>
            Attempt to gain unauthorized access to our Services or any user
            accounts
          </li>
          <li>
            Submit false, misleading, or inappropriate information to our
            Services
          </li>
          <li>
            Interfere with or disrupt the normal operation of our Services or
            any related networks and systems
          </li>
          <li>
            Distribute any malicious software, viruses, or other harmful
            materials through our Services
          </li>
          <li>
            Harass, threaten, or defame any person or entity using our Services.
          </li>
        </ol>
        <p>
          We reserve the right, in our sole discretion, to take any necessary
          actions, including legal actions, against users who violate these
          Terms or engage in any prohibited activities.
        </p>
        <h3>Disclaimer of Warranties</h3>
        <p>
          Our Services are provided on an &quotas is&quot and &quotas
          available&quot basis, without any warranties of any kind, either
          express or implied. Imago and its creators disclaim all warranties,
          including, but not limited to, the implied warranties of
          merchantability, fitness for a particular purpose, and
          non-infringement. We do not warrant that our Services will be
          uninterrupted, error-free, or completely secure.
        </p>
        <h3>Indemnification</h3>
        <p>
          You agree to indemnify, defend, and hold harmless Imago and its
          creators, affiliates, officers, directors, employees, and agents from
          and against any and all claims, liabilities, damages, losses, or
          expenses, including reasonable attorneys&#39 fees and costs, arising
          out of or in any way connected with your access to or use of our
          Services, your violation of these Terms, or any other activities or
          actions you take in connection with our Services.
        </p>
        <h3>Severability</h3>
        <p>
          If any provision of these Terms is found to be invalid or
          unenforceable by a court of competent jurisdiction, the remaining
          provisions shall remain in full force and effect, and the invalid or
          unenforceable provision shall be deemed superseded by a valid,
          enforceable provision that most closely matches the intent of the
          original provision.
        </p>
        <h3>No Waiver</h3>
        <p>
          The failure of Imago to enforce any right or provision of these Terms
          shall not be deemed a waiver of such right or provision. No waiver of
          any term of these Terms shall be deemed a further or continuing waiver
          of such term or any other term.
        </p>
        <h3>Entire Agreement</h3>
        <p>
          These Terms, together with our Privacy Policy and any other legal
          notices published on our website, constitute the entire agreement
          between you and Imago with respect to your use of our Services and
          supersede all prior or contemporaneous understandings, agreements,
          representations, or warranties, whether written or oral, regarding the
          subject matter of these Terms.
        </p>
        <h3>Assignment</h3>
        <p>
          You may not assign or transfer these Terms or your rights and
          obligations under these Terms, by operation of law or otherwise,
          without our prior written consent. We may freely assign or transfer
          these Terms and our rights and obligations under these Terms without
          restriction.
        </p>
        <h3>Force Majeure</h3>
        <p>
          Neither Imago nor its creators shall be held liable for any delay or
          failure in the performance of our Services or any part thereof,
          directly or indirectly resulting from causes beyond our reasonable
          control, including but not limited to acts of God, acts of civil or
          military authorities, fires, floods, earthquakes, epidemics,
          pandemics, wars, riots, civil unrest, sabotage, terrorism, internet
          failures, power outages, or any other similar or dissimilar
          occurrences.
        </p>
        <h3>Feedback and Submissions</h3>
        <p>
          We welcome your feedback, suggestions, and ideas about our Services.
          By submitting any feedback or ideas to us, you grant us an
          unrestricted, perpetual, irrevocable, non- exclusive, royalty-free,
          worldwide license to use, modify, reproduce, distribute, and display
          any such feedback or ideas in any form and for any purpose, without
          any obligation to compensate you.
        </p>
        <h3>Third-Party Links</h3>
        <p>
          Our Services may contain links to third-party websites or resources
          that are not owned or controlled by Imago. We have no control over and
          assume no responsibility for the content, privacy policies, or
          practices of any third-party websites or resources. You acknowledge
          and agree that Imago and its creators are not liable for any loss or
          damage that may be incurred by you as a result of the availability or
          content of such third-party websites or resources, or as a result of
          any reliance placed by you on the completeness, accuracy, or existence
          of any content or services provided by such third-party websites or
          resources.
        </p>
        <h3>Contact Information</h3>
        <p>
          If you have any questions or concerns regarding these Terms or our
          Services, please contact us at smartnibsevilla@gmail.com By using our
          Services, you acknowledge that you have read, understood, and agree to
          be bound by these Terms.
        </p>
      </div>
    </div>
  );
};

export default Terms;
