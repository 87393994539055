import React, { useContext, useEffect, useState } from "react";
import { ModalContext } from "../../context/ModalContext";
import { DreamsContext } from "../../context/DreamsContext";
import { InterpretationContext } from "../../context/InterpretationContext";
import DreamForm from "../../views/DreamForm";

const DreamCard = ({ dream }) => {
  const { getDreamInterpretations } = useContext(InterpretationContext);
  const { setDream, deleteDream, dream: contextDream, favoriteDream } = useContext(DreamsContext);
  const { modalComponent } = useContext(ModalContext);
  const [thumbnail, setThumbnail] = useState();


  useEffect(() => {
    if (!dream || !dream.dream_id) {
      return;
    }

    if (contextDream && dream.dream_id === contextDream.dream_id) {
      setThumbnail(contextDream.thumbnail);
    }
  }, [contextDream?.thumbnail]);


  const handleEdit = () => {
    setDream(dream);
    document.getElementById("root").scrollTo({ top: 0 });
    modalComponent('Edit Dream', <DreamForm />)
  };

  const handleLike = () => {
    favoriteDream(dream)
  };

  const handleInterpretation = () => {
    setDream(dream);
    getDreamInterpretations(dream.dream_id);
  };

  const handleDelete = () => {
    modalComponent(
      "Dream",
      <div>
        <p>¿Estás seguro que quieres eliminar el sueño {dream.title}?</p>
        <p>Esta acción NO puede deshacerse.</p>
        <button
          className="btn btn-danger"
          onClick={() => deleteDream(dream.dream_id)}
        >
          <i className="fa fa-trash me-1"></i> Eliminar
        </button>
      </div>
    );
  };

  return (
    dream && <div className="dream-card-container mb-3"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)), url("${thumbnail ? thumbnail : dream.thumbnail}")`
      }}
    >

      <div className="d-flex justify-content-between w-100 align-items-center">
        <button
          onClick={handleLike}
          className="btn p-0"
          style={{ height: "30px", width: '30px' }}>
          <i className={dream.favorite
            ? "fas fa-heart text-red"
            : "far fa-heart text-white"} />
        </button>
        <button onClick={handleInterpretation} className="btn btn-primary">
          <i className="fa fa-brain me-1"></i>Analysis
        </button>
      </div>
      <p className="dream-card-title">{dream.title}</p>
      <div className="col-12">
        <button
          onClick={handleEdit}
          className="btn btn-sm btn-circle btn-primary me-2"
        >
          <i className="fa fa-edit"></i>
        </button>
        <button
          onClick={handleDelete}
          className="btn btn-sm btn-circle btn-primary"
        >
          <i className="fa fa-trash"></i>
        </button>
      </div>
    </div>
  );
};

export default DreamCard;
