import React from "react";

const NumberCard = ({ label, value, description }) => {
  return (
    <div className="stats-card number mb-3">
      <div className="card-body">
        <h3 className="h6 bold">{label}</h3>
        <p className="h1">{isNaN(value) ? 0 : value}</p>
        {description && description !== null && (
          <p className="small mb-0">{description}</p>
        )}
      </div>
    </div>
  );
};

export default NumberCard;
