import React from "react";

const Privacy = () => {
  return (
    <div className="card mb-3">
      <div className="card-body">
        <h2>Privacy Policy</h2>
        <p>Last updated: 05-01-2023</p>
        <h3>Introduction</h3>
        <p>
          Imago is committed to protecting your privacy and ensuring that your
          personal information is handled in a safe and responsible manner. This
          Privacy Policy ("Policy") describes how we collect, use, and disclose
          your personal information when you access or use our website,
          products, and services ("Services"). By accessing or using our
          Services, you agree to the terms of this Policy. If you do not agree
          with this Policy, please do not access or use our Services.
        </p>
        <h3>Information We Collect</h3>
        <p>
          When you use our Services, we may collect the following types of
          information:
        </p>
        <ol>
          <li>
            Personal Information: Personal information is information that can
            be used to identify you, either directly or indirectly. We may
            collect personal information such as your name, email address, and
            any other information you voluntarily provide when you interact with
            our Services.
          </li>
          <li>
            Dream Descriptions and Interpretations: We collect the dream
            descriptions you provide and the AI-generated interpretations of
            those dreams as part of our Services.
          </li>
          <li>
            Usage Data: We may automatically collect usage data when you access
            or use our Services, including but not limited to your device&#39s
            Internet Protocol (IP) address, browser type, browser version, the
            pages of our Services that you visit, the time and date of your
            visit, the time spent on those pages, unique device identifiers, and
            other diagnostic data.
          </li>
          <li>
            Cookies and Tracking Technologies: We may use cookies and other
            tracking technologies to collect information about your activity on
            our Services. Cookies are small data files stored on your device
            that help us improve our Services and your experience, understand
            user preferences, and track user trends.
          </li>
        </ol>
        <h3>How We Use Your Information</h3>
        <p>
          We may use the information we collect for various purposes, including
          to:
        </p>
        <ol>
          <li>Provide and maintain our Services</li>
          <li>Improve, personalize, and expand our Services</li>
          <li>
            Communicate with you, including responding to your inquiries and
            providing updates about our Services
          </li>
          <li>
            Conduct research, including analyzing dream descriptions and
            interpretations for research purposes
          </li>
          <li>
            Monitor and analyze usage and trends to improve your experience with
            our Services
          </li>
          <li>Detect, prevent, and address technical and security issues</li>
          <li>
            Share aggregated or anonymized information with third parties for
            research, marketing, business, or other related purposes
          </li>
          <li>Comply with legal requirements and protect our legal rights.</li>
        </ol>
        <h3>How We Share Your Information</h3>
        <p>
          We may share your information with third parties under the following
          circumstances:
        </p>
        <ol>
          <li>
            Research and Development: We may share your dream descriptions,
            interpretations, and other data with third parties for research and
            development purposes. This information will be anonymized or
            aggregated to ensure your privacy is protected.
          </li>
          <li>
            Service Providers: We may share your information with third-party
            service providers who perform functions on our behalf, such as
            hosting, analytics, and customer support services.
          </li>
          <li>
            Legal Compliance: We may disclose your information if required to do
            so by law or in a good faith belief that such action is necessary to
            comply with legal obligations or protect the rights, property, or
            safety of Imago, its creators, our users, or the public.
          </li>
          <li>
            Business Transfers: In the event of a merger, acquisition, or sale
            of all or a portion of our assets, your information may be
            transferred as part of the transaction.
          </li>
        </ol>
        <h3>Security of Your Information</h3>
        <p>
          We take the security of your personal information seriously and employ
          reasonable technical and organizational measures to protect your
          information from unauthorized access, use, disclosure, or destruction.
          However, no method of transmission over the Internet or electronic
          storage is 100% secure, and we cannot guarantee the absolute security
          of your information.
        </p>
        <h3>Retention of Your Information</h3>
        <p>
          We will retain your personal information for as long as necessary to
          fulfill the purposes for which it was collected, including for
          research, marketing, business, or other related purposes, or as
          required by applicable laws, regulations, or contractual agreements.
          When your information is no longer required for these purposes, we
          will take appropriate steps to securely delete or anonymize your
          information.
        </p>
        <h3>Your Rights and Choices</h3>
        <p>
          You have certain rights and choices regarding the personal information
          we collect, use, and disclose:
        </p>
        <ol>
          <li>
            Access and Update: You may request access to your personal
            information and update or correct any inaccuracies by contacting us
            at [email address].
          </li>
          <li>
            Opt-Out: You may opt-out of receiving promotional communications
            from us by following the unsubscribe instructions included in each
            communication or by contacting us at [email address].
          </li>
          <li>
            Cookies: You can disable or delete cookies in your browser settings.
            Please note that if you choose to disable cookies, some features of
            our Services may not function properly.
          </li>
          <li>
            Data Deletion: If you would like to request the deletion of your
            personal information, please contact us at [email address]. We may
            retain certain information as required by law or for legitimate
            business purposes.
          </li>
        </ol>
        <h3>Children&#39;s Privacy</h3>
        <p>
          Our Services are not intended for use by children under the age of 13,
          and we do not knowingly collect personal information from children
          under 13. If we become aware that we have collected personal
          information from a child under 13, we will take steps to delete such
          information from our records.
        </p>
        <h3>International Data Transfers</h3>
        <p>
          Your information may be transferred to, stored, and processed in
          countries other than the country in which it was collected, including
          the United States. By using our Services, you consent to the transfer
          of your information to countries outside your country of residence,
          which may have different data protection rules than your country.
        </p>
        <h3>Changes to This Privacy Policy</h3>
        <p>
          We reserve the right to modify or update this Policy at any time, in
          our sole discretion. Any changes will be effective immediately upon
          posting the updated Policy on our website. Your continued use of our
          Services after such changes constitutes your acceptance of the new
          Policy. If you do not agree to any changes to this Policy, you must
          stop using our Services.
        </p>
        <h3>Contact Informations</h3>
        <p>
          If you have any questions or concerns regarding this Policy, our
          Services, or our privacy practices, please contact us at
          smartnibsevilla@gmail.com. By using our Services, you acknowledge that
          you have read, understood, and agree to be bound by this Privacy
          Policy.
        </p>
      </div>
    </div>
  );
};

export default Privacy;
