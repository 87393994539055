import React, { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";

const Modal = () => {
  const {
    title,
    content,
    children,
    onCancel,
    component,
    onSuccess,
    clearModal,
  } = useContext(ModalContext);
  return (
    <div
      className="modal fade"
      id="modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modal"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header border-0">
            <div className="col-10">
              <h5 className="modal-title">{title}</h5>
            </div>
            <div className="col-2 text-end">
              <button className="btn border text-white" onClick={clearModal}>
                <i className="fa fa-times"></i>
              </button>
            </div>
          </div>
          <div className="modal-body">
            {content}
            {component}
            {children}
          </div>
          {(onSuccess || onCancel) && (
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-secondary"
                data-dismiss="modal"
                onClick={onCancel}
              >
                Cancelar
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={onSuccess}
              >
                OK
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
