import React, { useReducer, createContext, useContext } from "react";
import { SHOW_SPINNER, HIDE_SPINNER, SET_EDIT_MODE } from "../types";
import {
  SET_DREAM,
  APPEND_DREAM,
  DREAMS_RECEIVED,
  SET_PROPERTY_DREAM,
  SET_DREAM_IMAGE,
  REMOVE_DREAM,
} from "../types/dreams";
import DreamsService from "../services/DreamsService";
//import FilesService from '../services/FilesService';
import DreamsReducer from "../reducers/DreamsReducer";
import { ModalContext } from "./ModalContext";
import { InterpretationContext } from "./InterpretationContext";

const initialState = {
  editMode: false,
  spinner: false,
  dreams: null,
  dream: null,
  current_dream_image: null,
};

export const DreamsContext = createContext(initialState);

export const DreamsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(DreamsReducer, initialState);
  const { postInterpretation } = useContext(InterpretationContext);
  const { success, clearModal } = useContext(ModalContext);

  const getDreams = (filters) => {

    if (!filters) { return }

    DreamsService.getDreams(filters).then((res) => {
      const { dreams } = res.data;
      dispatch({ type: DREAMS_RECEIVED, payload: dreams });
    });
  };

  const getSingleDream = (dream_id) => {
    DreamsService.getSingleDream(dream_id).then((res) => {
      const { dream } = res.data;
      dispatch({ type: SET_DREAM, payload: dream });
    });
  };

  const getDreamImage = (dream) => {
    dispatch({ type: SHOW_SPINNER });
    DreamsService.getDreamImage(dream).then((res) => {
      const dreamImage = res.data;
      dispatch({ type: SET_DREAM_IMAGE, payload: dreamImage });
      setPropertyDream('thumbnail', dreamImage)
    }).finally(() => {
      dispatch({ type: HIDE_SPINNER });
    });
  };

  const setDream = (dream) => {
    dispatch({ type: SET_DREAM, payload: dream });
  };

  const createDream = () => {
    dispatch({ type: SET_DREAM, payload: { content: "", title: "" } });
    dispatch({ type: SET_EDIT_MODE, payload: true });
  };

  const setPropertyDream = (key, value) => {
    dispatch({ type: SET_PROPERTY_DREAM, payload: { key, value } });
  };

  const setEditMode = (editMode) => {
    dispatch({ type: SET_EDIT_MODE, payload: editMode });
  };

  const saveDreamList = (dreams, user) => {
    dispatch({ type: SHOW_SPINNER });
    let dreamsToUpload = dreams.filter(
      (dream) => dream.user_id === null || !dream.user_id
    );
    const promises = [];
    if (dreamsToUpload.length > 0) {
      dreamsToUpload.forEach((dream) => {
        dream.user_id = user.user_id;
        promises.push(DreamsService.putDream(dream));
      });
    }
    Promise.all(promises)
      .then(() => {
        dispatch({ type: HIDE_SPINNER });
        getDreams();
      })
      .catch((error) => {
        alert(error);
        dispatch({ type: HIDE_SPINNER });
        getDreams();
      });
  };


  const saveDream = async (dream, hideAlert) => {
    dispatch({ type: SHOW_SPINNER });

    try {
      let service = DreamsService.putDream;
      if (!dream.dream_id) {
        service = DreamsService.postDream;
      }

      // if (dream.image) {
      //   const file = await fetchAndCreateFile(dream.image, dream.title);
      //   dream.file_id = await saveImageToServer(file);
      // }

      const response = await service(dream);

      !hideAlert && success("Dream saved!");
      dispatch({ type: HIDE_SPINNER });
      dispatch({ type: SET_EDIT_MODE, payload: false });

      if (!dream.dream_id) {
        const newDream = response.data.dream;
        postInterpretation(newDream.dream_id);
        dispatch({ type: SET_DREAM, payload: newDream });
        dispatch({ type: APPEND_DREAM, payload: newDream });
      }
    } catch (error) {
      alert(error);
      dispatch({ type: HIDE_SPINNER });
      dispatch({ type: SET_EDIT_MODE, payload: false });
    }
  };

  const favoriteDream = async (dream) => {
    saveDream({ ...dream, favorite: !dream.favorite }, true).then(() => {
      dispatch({ type: SET_DREAM, payload: {} });
      success(!dream.favorite ? "Added Dream to favorites" : "Removed Dream from favorites")
    })
  }

  //image files
  // const fetchAndCreateFile = async (imageURL, dreamTitle) => {
  //   try {
  //     const response = await fetch(imageURL);
  //     const imageBlob = await response.blob();
  //     return new File([imageBlob], dreamTitle, { type: "image/jpeg" });
  //   } catch (error) {
  //     throw new Error(`Failed to fetch and create file: ${error.message}`);
  //   }
  // };

  // const saveImageToServer = async (file) => {
  //   try {
  //     const formData = new FormData();
  //     formData.append("file", file);
  //     const uploadedFile = await FilesService.postFile(formData);
  //     return uploadedFile.data.file.file_id;
  //   } catch (error) {
  //     throw new Error(`Failed to save image to the server: ${error.message}`);
  //   }
  // };


  const deleteDream = (dream_id) => {
    DreamsService.deleteDream(dream_id).then(() => {
      dispatch({ type: REMOVE_DREAM, payload: dream_id });
      success("Dream deleted.");
      clearModal();
      getDreams();
    });
  };

  const clearDreams = () => {
    dispatch({ type: DREAMS_RECEIVED, payload: null });
  };

  return (
    <DreamsContext.Provider
      value={{
        ...state,
        setDream,
        getDreams,
        saveDream,
        clearDreams,
        deleteDream,
        createDream,
        setEditMode,
        getDreamImage,
        saveDreamList,
        getSingleDream,
        setPropertyDream,
        favoriteDream,
      }}
    >
      {children}
    </DreamsContext.Provider>
  );
};
