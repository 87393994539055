// import { getCode, getName } from "country-list";
import { Country, State, City } from "country-state-city";
import SearchSelect from "./SearchSelect";

const LocationForm = ({
    selectedCountry,
    setSelectedCountry,
    selectedState,
    setSelectedState,
    selectedCity,
    setSelectedCity,
    flex,
    style,
}) => {
    return (
        <div className={flex && "d-flex flex-wrap justify-content-between"}>
            <div className={`col-12 ${flex && "col-md-4 pe-1"}`}>
                <SearchSelect
                    label="Country"
                    style={style}
                    className="text-dark mb-4"
                    options={Country.getAllCountries()}
                    getOptionLabel={(options) => {
                        return options["name"];
                    }}
                    getOptionValue={(options) => {
                        return options["name"];
                    }}
                    value={selectedCountry}
                    onChange={(item) => {
                        setSelectedCountry(item);
                    }}
                />
            </div>

            <div className={`col-12 ${flex && "px-1 col-md-4"}`}>
                <SearchSelect
                    label="State"
                    style={style}
                    className="text-dark mb-4"
                    options={State.getStatesOfCountry(selectedCountry?.isoCode)}
                    getOptionLabel={(options) => {
                        return options["name"];
                    }}
                    getOptionValue={(options) => {
                        return options["name"];
                    }}
                    value={selectedState}
                    onChange={(item) => {
                        setSelectedState(item);
                    }}
                />
            </div>

            <div className={`col-12 ${flex && "ps-1 col-md-4"}`}>
                <SearchSelect
                    label="City"
                    style={style}
                    className="text-dark mb-4"
                    options={City.getCitiesOfState(
                        selectedState?.countryCode,
                        selectedState?.isoCode
                    )}
                    getOptionLabel={(options) => {
                        return options["name"];
                    }}
                    getOptionValue={(options) => {
                        return options["name"];
                    }}
                    value={selectedCity}
                    onChange={(item) => {
                        setSelectedCity(item);
                    }}
                />
            </div>
        </div>
    );
};

export default LocationForm;
