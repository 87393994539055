import React from "react";
import DreamForm from "./DreamForm";
import DreamList from "./DreamList";

const DreamView = () => {
  return (
    <div className="container-fluid row mx-0">
      <div className="md-col-6 col-lg-5 mb-4" style={{ marginTop: '5%' }}>
        <DreamForm />
      </div>
      <div className="md-col-6 col-lg-7 mt-auto mb-auto">
        <DreamList />
      </div>
    </div>
  );
};

export default DreamView;
