import React, { useContext } from "react";
import StripeCheckout from "../components/common/StripeCheckout";
import { ModalContext } from "../context/ModalContext";
import Auth from "../components/auth/Auth";
import { AuthContext } from "../context/AuthContext";

const Donate = () => {
  const { user } = useContext(AuthContext);
  const { modalComponent } = useContext(ModalContext);

  const handleAuth = () => {
    modalComponent("Login to Save Your Dreams", <Auth />);
  };

  const renderContent = () => {
    if (user === null) {
      return (
        <div>
          <p>For security reasons, you need to sign in to donate.</p>
          <button className="btn btn-primary" onClick={handleAuth}>
            Sign In
          </button>
        </div>
      );
    }
    return <StripeCheckout />;
  };

  return (
    <div className="container" style={{ maxWidth: '500px' }}>
      <h1>Donate</h1>
      <p className="mb-5">Commodo magna cupidatat cupidatat sunt culpa cillum ipsum. Laboris do velit proident sunt enim voluptate Lorem aute proident. Consequat eu ea veniam nostrud Lorem aliquip incididunt deserunt anim ea mollit commodo. Irure ex voluptate irure enim cupidatat occaecat est Lorem enim voluptate.</p>
      <div className="card p-4 shadow">{renderContent()}</div>
    </div>
  );
};

export default Donate;
