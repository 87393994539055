import React from "react";
import Terms from "./Terms";
import Privacy from "./Privacy";

const About = () => {
  return (
    <div className="container py-3">
      <Terms />
      <Privacy />
    </div>
  );
};

export default About;
