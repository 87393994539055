import Main from "./Main";
import { AnalyticsProvider } from "./context/AnalyticsContext";
import { UserProvider } from "./context/AuthContext";
import { DreamsProvider } from "./context/DreamsContext";
import { InterpretationProvider } from "./context/InterpretationContext";
import { ModalProvider } from "./context/ModalContext";
import "./index.css";

function App() {
  return (
    <ModalProvider>
      <UserProvider>
        <InterpretationProvider>
          <DreamsProvider>
            <AnalyticsProvider>
              <Main />
            </AnalyticsProvider>
          </DreamsProvider>
        </InterpretationProvider>
      </UserProvider>
    </ModalProvider>
  );
}

export default App;
