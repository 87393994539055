import moment from "moment";

const stripe_dev_key =
  "pk_live_51KCZiiIWGnlrjQbyGVdfStY5hDoC15jA57eJiP3MLwhgAZGrqzHTAB258zRnaygc2tiVNnx328Tq45mLCyPNQTad00NAirlOQV";

const stripe_live_key =
  "pk_live_51KCZiiIWGnlrjQbyGVdfStY5hDoC15jA57eJiP3MLwhgAZGrqzHTAB258zRnaygc2tiVNnx328Tq45mLCyPNQTad00NAirlOQV";

export const S3_ENDPOINT = "https://dreams.s3.us-west-1.amazonaws.com";

export const STRIPE_KEY =
  process.env.NODE_ENV === "development" ? stripe_dev_key : stripe_live_key;

export const BASE_URL =
  (process.env.NODE_ENV === "development"
    ? "http://localhost:4000"
    : "https://evolbs.com") + "/api";

export const searchRows = (query, rows) => {
  if (!rows) return;
  if (isNaN(query)) query = query.toLowerCase();
  let searchResult = rows.filter((row) => {
    let result = Object.keys(row).filter((column) => {
      if (Array.isArray(row[column])) {
        return row[column].filter((subcolumn) => {
          if (isNaN(subcolumn)) {
            if (subcolumn.toLowerCase().includes(query)) return row;
          } else if (subcolumn === query) return row;
          return null;
        });
      }
      if (isNaN(row[column])) {
        if (String(row[column]).toLowerCase().includes(query)) {
          return row;
        }
      } else if (String(row[column]) === query) {
        return row;
      } else if (Number(row[column]) === Number(query)) {
        return row;
      }
      return null;
    });
    return result.length > 0;
  });
  return searchResult;
};

export const getArgs = (args) => {
  if (args && args !== null) {
    const array = Object.keys(args)
      .map((key) => {
        if (args[key] && args[key] !== null && args[key] !== "") {
          return `${key}=${args[key]}`;
        }
        return null;
      })
      .filter((arg) => arg !== null);
    if (array.length > 0) {
      return `&${array.join("&")}`;
    }
  }
  return "";
};

export const calcularTotal = (productos) => {
  if (productos && productos !== null) {
    let total = 0;
    productos.forEach((producto) => {
      total += producto.precio * producto.cantidad;
    });
    return total;
  }
  return 0;
};

export function formatMonto(monto) {
  monto = parseFloat(monto);
  if (!monto || monto === null || isNaN(monto)) monto = 0;
  return numberWithCommas(monto);
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const hideModal = () => {
  const button = document.getElementById("main-button");
  if (button && button !== null) {
    button.click();
  }
};

export const showModal = () => {
  const button = document.getElementById("main-button");
  if (button && button !== null) {
    button.click();
  } else {
    const newButton = document.createElement("button");
    newButton.attributes.href = "#modal";
    newButton.id = "main-button";
    newButton.setAttribute("data-toggle", "modal");
    newButton.setAttribute("data-target", "#modal");
    newButton.setAttribute("data-bs-toggle", "modal");
    newButton.setAttribute("data-bs-target", "#modal");
    newButton.style.visibility = "hidden";
    document.body.appendChild(newButton);
    newButton.click();
  }
};

export const randomString = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getCardType = (number) =>
  String(number) === "3"
    ? "amex"
    : String(number) === "4"
    ? "visa"
    : "mastercard";

export const categories = {
  dance: [11, 12, 13, 15],
  workout: [14, 15],
  presenciales: [11742, 11743, 11744, 11745],
};

export const getCompradasPresenciales = (paquetes) => {
  const package_ids = categories.presenciales;
  const customer_classes = paquetes.filter((class_category) =>
    package_ids.includes(class_category.package_class_id)
  );
  let total = 0;
  customer_classes.forEach((purchase) => {
    total += purchase.available_class;
  });
  return total;
};

export const getPresencialesVigentes = (paquetes) => {
  const package_ids = categories.presenciales;
  const customer_classes = paquetes.filter(
    (class_category) =>
      package_ids.includes(class_category.package_class_id) &&
      moment(class_category.created_at)
        .add(class_category.package_days, "days")
        .isAfter(moment())
  );
  let total = 0;
  customer_classes.forEach((purchase) => {
    total += purchase.available_class;
  });
  return total;
};

export const getVigencia = (handle, user) => {
  if (user.vigencias) {
    const paquete = user.vigencias.find(
      (class_category) => class_category.handle === handle
    );
    if (paquete) {
      return paquete.expiration;
    }
  }
};

export const cardStyle = {
  style: {
    base: {
      color: "#fff",
      fontFamily: "Arial, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#fff",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export const mapas = {
  1: [4, 6],
  2: [2, 4, 4],
};

export const iconosMapas = {
  1: "fas fa-bicycle",
  2: "fas fa-praying-hands",
};

export const durations = [
  "10 a 19 minutos",
  "20 a 29 minutos",
  "30 a 39 minutos",
  "40 a 49 minutos",
  "50 a 59 minutos",
  "1 hora o mas",
];

export const CANCEL_TIMEFRAME = 4;

export const canCancel = (single_class) => {
  const currentHour = moment();
  const classHour = moment(single_class.class_date);
  const diff = classHour.diff(currentHour, "hours") + 6;
  return diff >= CANCEL_TIMEFRAME;
};

export const getStatusReservacion = (reservacion) => {
  if (reservacion.deletedAt !== null) {
    return <span className="text-danger">Cancelada</span>;
  }
  if (reservacion.is_cash) {
    if (!reservacion.is_paid) {
      return <span className="text-danger">Pago en Efectivo Incompleto</span>;
    }
  }
  if (
    !reservacion.attend &&
    moment().isAfter(moment(reservacion.single_class.class_date))
  ) {
    return <span className="text-warning">No Asistió</span>;
  }
  if (reservacion.attend) {
    return <span className="text-success">Exitosa</span>;
  }
  return <span className="text-secondary">Próxima</span>;
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validatePhoneNumber = (input_str) => {
  var re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
  return re.test(input_str);
};

export const getValue = (object, key, boolean) => {
  if (object && object !== null) {
    const value = object[key];
    if (value && value !== null) {
      if (boolean) return value === true || value === 1;
      return value;
    }
  }
  if (boolean) return false;
  return "";
};
