import api from "./api";

const route = "/locations";

const LocationsService = {
  getLocation: (lat, lng) => api.get(`${route}?lat=${lat}&lng=${lng}`),
  getCountries: () => api.get(`${route}/countries`),
  getCities: (country) => api.get(`${route}/cities?country=${country}`),
};
export default LocationsService;
