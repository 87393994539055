import UserForm from "../components/auth/UserForm";
import LocationForm from "../components/global/LocationForm";

const EditUser = () => {
    return (
        <div className="row justify-content-center">
            <div className="card col-10 col-md-5 p-4">
                <h3 className="mb-4">Edit User</h3>
                <UserForm />
            </div>
        </div>
    );
}

export default EditUser;