import React from "react";

const ThankYou = () => {
  return (
    <div className="container text-center">
      <h1>Thank You!</h1>
      <p>We appreciate your support.</p>
      <img
        src="/images/freud.png"
        alt="logo"
        className="freud d-block m-auto mb-3"
      />
    </div>
  );
};

export default ThankYou;
