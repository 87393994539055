import React from 'react';
import moment from 'moment';
import Chart from 'react-apexcharts';

const MonthlyStatsChart = ({ monthly }) => {
    const data = new Array(12).fill(1);

    data.forEach((one, index) => {
        const favoritedDreams = monthly.favorited_dreams.find((mes) => mes.month === index + 1);
        const user_dreams = monthly.user_dreams.find((mes) => mes.month === index + 1);
        const dreams = monthly.dreams.find((mes) => mes.month === index + 1);
        const users = monthly.users.find((mes) => mes.month === index + 1);

        data[index] = {
            favoritedDreams: favoritedDreams !== undefined ? favoritedDreams.total_favorited_dreams : 0,
            user_dreams: user_dreams !== undefined ? user_dreams.user_dreams : 0,
            dreams: dreams !== undefined ? dreams.dreams : 0,
            users: users !== undefined ? users.users : 0,
            mes: moment(index + 1, 'MM').format('MMM'),
        };
    });

    const series = [
        { name: 'Favorited', data: data.map(({ favoritedDreams }) => favoritedDreams), color: '#8e7daa' },
        { name: 'Dreams per User (Average)', data: data.map(({ user_dreams }) => user_dreams), color: '#b8a4c3' },
        { name: 'Dreams', data: data.map(({ dreams }) => dreams), color: '#d9c9e4' },
        { name: 'Users', data: data.map(({ users }) => users), color: '#f0ebf7' },
    ];

    const options = {
        chart: {
            type: 'line',
            height: 440,
            theme: 'dark'
        },
        colors: ['#6a5f9e', '#8b79bb', '#ae9fd2', '#d3c1f1'],
        xaxis: {
            categories: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            labels: {
                style: {
                    colors: 'white',
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: 'white',
                },
            },
        },
        stroke: {
            curve: 'smooth',
        },
        legend: {
            labels: {
                colors: 'white',
            },
        },
        tooltip: {
            theme: 'dark',
        },
        fill: {
            colors: ['#6a5f9e', '#8b79bb', '#ae9fd2', '#d3c1f1'],
        },
        markers: {
            colors: ['#6a5f9e', '#8b79bb', '#ae9fd2', '#d3c1f1'],
        },
        grid: {
            borderColor: 'rgba(255, 255, 255, 0.1)',
        },
    };

    return (
        <div className="stats-card p-2" style={{ color: 'white' }}>
            <Chart type="line" height={440} width={'100%'} options={options} series={series} />
        </div>
    );
};

export default MonthlyStatsChart;
