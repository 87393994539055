import './dreams.css';
import { ReactComponent as PlusIcon } from '../../assets/icons/evolbs_icon1.svg';
import { useContext } from 'react';
import { ModalContext } from '../../context/ModalContext';
import DreamForm from '../../views/DreamForm';
import { DreamsContext } from '../../context/DreamsContext';

const DreamFeedPlaceholder = () => {
    const { modalComponent } = useContext(ModalContext)
    const { setDream } = useContext(DreamsContext)


    const handleCreateNewDream = () => {
        setDream({})
        modalComponent('New Dream', <DreamForm />)
    }


    return (
        <div className='d-flex flex-wrap justify-content-center'>
            <div className='dream-feed-placeholder-column'>
                <div className="dream-placeholder-card height-2" style={{ marginTop: '20px' }} />
                <div className="dream-placeholder-card height-3" />
            </div>

            <div className='dream-feed-placeholder-column'>
                <div className="dream-placeholder-card height-3" />
                <div className="dream-placeholder-card height-1" />
            </div>

            <div className='dream-feed-placeholder-column'>
                <div className="dream-placeholder-card height-2" />
                <div className="dream-placeholder-card height-1" />
            </div>

            <div className='dream-feed-placeholder-column'>
                <div className="dream-placeholder-card height-2" style={{ marginTop: '20px' }} />
                <div className="dream-placeholder-card height-2" />
            </div>

            <div className='dream-feed-placeholder-column'>
                <div className="dream-placeholder-card height-3" />
                <div className="dream-placeholder-card height-1" />
            </div>
        </div>
    );
}

export default DreamFeedPlaceholder;
