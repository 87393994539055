import React, { useReducer, createContext } from "react";
import AnalyticsReducer from "../reducers/AnalyticsReducer";
import AnalyticsService from "../services/AnalyticsService";
import {
  GLOBAL_STATS_RECEIVED,
  MONTHLY_STATS_RECEIVED,
} from "../types/analytics";

const initialState = {
  global: null,
  monthly: null,
};

export const AnalyticsContext = createContext(initialState);

export const AnalyticsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AnalyticsReducer, initialState);

  const getGlobalStats = (filters) => {
    AnalyticsService.getGlobalStats(filters).then((res) => {
      dispatch({ type: GLOBAL_STATS_RECEIVED, payload: res.data });
    });
  };

  const getMonthlyStats = (filters) => {
    AnalyticsService.getMonthlyStats(filters).then((res) => {
      dispatch({ type: MONTHLY_STATS_RECEIVED, payload: res.data });
    });
  };

  return (
    <AnalyticsContext.Provider
      value={{ ...state, getGlobalStats, getMonthlyStats }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};
